"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractGuestOperationFields = void 0;
/**
 * Extract fields necessary to perform sorting and filtering operations on
 * `GuestExternal` records based on the information in the given `guest`.
 */
function extractGuestOperationFields(guest) {
    const fields = {};
    if (typeof guest.name === 'string' && guest.name.length > 0) {
        // Different fields with the same value is necessary for searching and
        // filtering because `_sortGuestName` can not be used in a filter expression
        // because it will be a part of the primary key on the index
        fields._filterGuestName = guest.name.toLowerCase();
        fields._sortGuestName = guest.name.toLowerCase();
    }
    if (Array.isArray(guest.tags)) {
        fields._flatTags = guest.tags.join(',').toLowerCase();
    }
    return fields;
}
exports.extractGuestOperationFields = extractGuestOperationFields;
